import React, {useEffect, useRef, useState} from 'react';
import { CListGroup, CSpinner, CListGroupItem, CInputGroup, CInputGroupText, CInput, CCol } from '@coreui/react'
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
const url = process.env.REACT_APP_BACK_URL;

const AxesDropdown = ({problem, setSelectedDimension, selectedDimension}) => {

    const dispatch = useDispatch();

    const dimensions = useSelector((state) => state.dimensions.data);
    const [stateDimensions, setStateDimensions] = useState([])
    const [showDropdown, setDropdown] = useState(false)
    const [inputValue, setInputValue] = useState(problem ? problem.axis : '')
    const [isLoading, setLoading] = useState(false)
    const initialRender = useRef(true)

    useEffect(() => {
        setLoading(true)
        fetch(`${url}/pub/elements/dimensions`)
        .then(response => response.json())
        .then(response => dispatch({type: 'setDimensions', data: response.elements[0].tree_elements_complete}))
        .then(() => initialRender.current = false)
    },[])

    useEffect(() => {
        if (!initialRender.current) {
            setStateDimensions(dimensions)
            setLoading(false);
        } 
    }, [dimensions])

    const defaultDimension = () => {        
        return stateDimensions.find((dimension) => dimension.slug === (selectedDimension && selectedDimension.slug ? selectedDimension.slug : selectedDimension))  
    }
    
    if (isLoading) {
        return(
            <CSpinner style={{display:'block', margin: '0 auto'}}/>
        )
    } else {
        return  (
            <div>
                <br/>
                <CInputGroup className={"autoCompleteContainer"}>
                    <Autocomplete
                        size="small"
                        value={defaultDimension()}
                        options={dimensions}
                        renderInput={(params) => <TextField {...params} label="Dimension a la que pertenece" variant="outlined" />}
                        style={{ width: 300, height: 50 }}
                        getOptionLabel={(option) => option.title}
                        isSearchable={true}
                        getOptionSelected={(option) => option.slug}
                        onChange={(e, selected) => setSelectedDimension(selected.slug)}
                    />                    
                </CInputGroup>
            </div>
            )
    }
}

export default AxesDropdown;