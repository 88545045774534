import React from 'react';

//INECIP routes

const Axis = React.lazy(() => import('./components/axis/Axis'))
const AxisForm = React.lazy(() => import('./components/axis/AxisForm/AxisForm'))

const Dimensions = React.lazy(() => import('./components/dimensions/Dimensions'))
const DimensionsForm = React.lazy(() => import('./components/dimensions/DimensionsForm'))

const Problems = React.lazy(() => import('./components/problems/Problems'))
const ProblemsForm = React.lazy(() => import('./components/problems/ProblemsForm'))

const Resources = React.lazy(() => import('./components/resources/Resources'))
const ResourcesForm = React.lazy(() => import('./components/resources/ResourcesForm'))

const AboutUs = React.lazy(() => import('./components/aboutus/AboutUs'));

const Trainings = React.lazy(() => import('./components/trainings/Trainings'));
const TrainingForm = React.lazy(() => import('./components/trainings/TrainingForm'))

const Users = React.lazy(() => import('./components/users/Users'))
const UsersForm = React.lazy(() => import('./components/users/UsersForm'))

// examples

const Toaster = React.lazy(() => import('./views/notifications/toaster/Toaster'));
const Tables = React.lazy(() => import('./views/base/tables/Tables'));

const Breadcrumbs = React.lazy(() => import('./views/base/breadcrumbs/Breadcrumbs'));
const Cards = React.lazy(() => import('./views/base/cards/Cards'));
const Carousels = React.lazy(() => import('./views/base/carousels/Carousels'));
const Collapses = React.lazy(() => import('./views/base/collapses/Collapses'));
const BasicForms = React.lazy(() => import('./views/base/forms/BasicForms'));

const Jumbotrons = React.lazy(() => import('./views/base/jumbotrons/Jumbotrons'));
const ListGroups = React.lazy(() => import('./views/base/list-groups/ListGroups'));
const Navbars = React.lazy(() => import('./views/base/navbars/Navbars'));
const Navs = React.lazy(() => import('./views/base/navs/Navs'));
const Paginations = React.lazy(() => import('./views/base/paginations/Pagnations'));
const Popovers = React.lazy(() => import('./views/base/popovers/Popovers'));
const ProgressBar = React.lazy(() => import('./views/base/progress-bar/ProgressBar'));
const Switches = React.lazy(() => import('./views/base/switches/Switches'));

const Tabs = React.lazy(() => import('./views/base/tabs/Tabs'));
const Tooltips = React.lazy(() => import('./views/base/tooltips/Tooltips'));
const BrandButtons = React.lazy(() => import('./views/buttons/brand-buttons/BrandButtons'));
const ButtonDropdowns = React.lazy(() => import('./views/buttons/button-dropdowns/ButtonDropdowns'));
const ButtonGroups = React.lazy(() => import('./views/buttons/button-groups/ButtonGroups'));
const Buttons = React.lazy(() => import('./views/buttons/buttons/Buttons'));
const Charts = React.lazy(() => import('./views/charts/Charts'));
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
const CoreUIIcons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'));
const Flags = React.lazy(() => import('./views/icons/flags/Flags'));
const Brands = React.lazy(() => import('./views/icons/brands/Brands'));
const Alerts = React.lazy(() => import('./views/notifications/alerts/Alerts'));
const Badges = React.lazy(() => import('./views/notifications/badges/Badges'));
const Modals = React.lazy(() => import('./views/notifications/modals/Modals'));
const Colors = React.lazy(() => import('./views/theme/colors/Colors'));
const Typography = React.lazy(() => import('./views/theme/typography/Typography'));
const Widgets = React.lazy(() => import('./views/widgets/Widgets'));
//const Users = React.lazy(() => import('./views/users/Users'));
const User = React.lazy(() => import('./views/users/User'));



const routes = [
  //INECIP routes

  { path: '/axes', exact: true, name: 'Ejes', component: Axis },
  { path: '/axes/new', exact: true, name: 'Nuevo eje', component: AxisForm },
  { path: '/axes/:axisId', exact: true, name: 'Editar eje', component: AxisForm },

  { path: '/dimensions', exact: true, name: 'Dimensiones', component: Dimensions },
  { path: '/dimensions/new', exact: true, name: 'Nueva dimension', component: DimensionsForm },
  { path: '/dimensions/:dimensionId', exact: true, name: 'Editar dimension', component: DimensionsForm },

  { path: '/problems', exact: true, name: 'Problemas', component: Problems },
  { path: '/problems/new', exact: true, name: 'Nuevo problema', component: ProblemsForm },
  { path: '/problems/:problemId', exact: true, name: 'Editar problema', component: ProblemsForm },

  // { path: '/resources', exact: true, name: 'Insumos', component: Resources },
  // { path: '/resources/new', exact: true, name: 'Nuevo insumo', component: ResourcesForm },
  // { path: '/resources/:resourceId', exact: true, name: 'Editar insumo', component: ResourcesForm },

  { path: '/users', exact: true, name: 'Usuarios', component: Users },
  { path: '/users/new', exact: true, name: 'Nuevo usuario', component: UsersForm },
  { path: '/users/:userId', exact: true, name: 'Editar usuario', component: UsersForm },

  // About us - route.
  {path: '/aboutus',  exact: true, name: 'Sobre nosotros', component: AboutUs},

  // trainings - route
  {path: '/trainings',  exact: true, name: 'Capacitaciones', component: Trainings},
  { path: '/trainings/new', exact: true, name: 'Nueva capacitación', component: TrainingForm },
  { path: '/trainings/:trainingId', exact: true, name: 'Editar capacitación', component: TrainingForm },

  //default routes
  { path: '/inicio', exact: true, name: 'Inicio', component: Dashboard },
  { path: '/theme', name: 'Theme', component: Colors, exact: true },
  { path: '/theme/colors', name: 'Colors', component: Colors },
  { path: '/theme/typography', name: 'Typography', component: Typography },
  { path: '/base', name: 'Base', component: Cards, exact: true },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
  { path: '/base/cards', name: 'Cards', component: Cards },
  { path: '/base/carousels', name: 'Carousel', component: Carousels },
  { path: '/base/collapses', name: 'Collapse', component: Collapses },
  { path: '/base/forms', name: 'Forms', component: BasicForms },
  { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons },
  { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
  { path: '/base/navbars', name: 'Navbars', component: Navbars },
  { path: '/base/navs', name: 'Navs', component: Navs },
  { path: '/base/paginations', name: 'Paginations', component: Paginations },
  { path: '/base/popovers', name: 'Popovers', component: Popovers },
  { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar },
  { path: '/base/switches', name: 'Switches', component: Switches },
  { path: '/base/tables', name: 'Tables', component: Tables },
  { path: '/base/tabs', name: 'Tabs', component: Tabs },
  { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
  { path: '/buttons', name: 'Buttons', component: Buttons, exact: true },
  { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
  { path: '/buttons/button-dropdowns', name: 'Dropdowns', component: ButtonDropdowns },
  { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
  { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons },
  { path: '/charts', name: 'Charts', component: Charts },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', component: Flags },
  { path: '/icons/brands', name: 'Brands', component: Brands },
  { path: '/notifications', name: 'Notifications', component: Alerts, exact: true },
  { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
  { path: '/notifications/badges', name: 'Badges', component: Badges },
  { path: '/notifications/modals', name: 'Modals', component: Modals },
  { path: '/notifications/toaster', name: 'Toaster', component: Toaster },
  { path: '/widgets', name: 'Widgets', component: Widgets },
  { path: '/users', exact: true,  name: 'Users', component: Users },
  { path: '/users/:id', exact: true, name: 'User Details', component: User }
];

export default routes;
