export default (problemsState = [], actionParams) => {
    switch (actionParams.type) {
      case 'setProblems':
        return {...problemsState, data: actionParams.data}
      case 'addProblem':
        if (actionParams.problem.index) {
          let newProblems = problemsState.data;
          newProblems[actionParams.problem.index] = actionParams.problem;
          return { ...problemsState, data: newProblems }  
        }
        return { ...problemsState, data: [...problemsState.data, actionParams.problem] }      
      case 'removeProblem':
          let newProblems = problemsState.data;
          newProblems = newProblems.filter(problem => problem.title !== actionParams.problem.title)
          return { ...problemsState, data: newProblems }  
      default:
        return problemsState
    }
  }