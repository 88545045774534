import React, { useEffect, useState, useMemo } from 'react';
import {
  CCard,
  CButton,
  CLabel,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CInputGroup,
  CInputGroupText,
  CTextarea,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import DimensionsDropdown from './DimensionsDropdown';
import ContentsForm from '../contentsForm/ContentsForm';
import ReactQuill, { Quill } from 'react-quill';
import { cleanLinebreaks } from '../utils';

const Font = Quill.import('formats/font');
Font.whitelist = ['Roboto', 'Raleway', 'Alegreya-Sans'];
Quill.register(Font, true);

const url = process.env.REACT_APP_BACK_URL;

const DimensionsForm = ({ match }) => {
  ///////dimensions
  const allProblems = useSelector((state) => state.problems.data);
  const history = useHistory();

  const problem = useSelector(({ problems }) =>
    match.params.problemId && problems?.data ? problems.data[match.params.problemId] : undefined
  );
  const [title, setTitle] = useState(problem ? problem.title : '');
  const [titleStyle, setTitleStyle] = useState(problem?.title_style ?? title);
  const [description, setDescription] = useState(problem ? problem.description : '');
  const [selectedDimension, setSelectedDimension] = useState(problem ? problem.parent_slug : '');
  const [canSave, setCanSave] = useState(!!problem);
  const [contents, setContents] = useState(problem && problem.contents ? problem.contents : []);
  const [validUrl, setValidUrl] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (title && selectedDimension && validUrl) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [title, description, selectedDimension, validUrl]);

  const addProblem = () => {
    setSaving(true);
    let problemToAdd = {
      element: {
        title,
        title_style: titleStyle,
        description,
        order: allProblems.length + 1,
        parent_slug: selectedDimension,
        color: '#eeeeee',
      },
      contents,
    };
    let routeParam = problem ? `/${problem.slug}` : '';
    if (canSave) {
      fetch(`${url}/prv/elements/problems` + routeParam, {
        method: problem ? 'PUT' : 'POST',
        headers: {
          Authorization: 'bearer ' + localStorage.getItem('inecip_access_token'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(problemToAdd),
      })
        .then(() => {
          dispatch({ type: 'addProblem', problem: problemToAdd });
        })
        .then(() => {
          setSaving(false);
          history.push('/problems');
        })
        .catch(() => setError(true));
    }
  };

  const handleTitleChange = (value, delta, source, editor) => {
    setTitleStyle(value);
    setTitle(cleanLinebreaks(editor.getText()));
  };

  const modules = useMemo(
    () => ({
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize'],
      },
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: Font.whitelist }],
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['image', 'link'],
        ],
      },
    }),
    []
  );

  if (!allProblems) {
    history.push('/problems');
    return null;
  }

  return (
    <CRow>
      <CCol xs={12}>
        <Link to='/problems'>
          <CButton type='button' color='info' id='button-addon1' style={{ marginBottom: '30px' }}>
            Volver
          </CButton>
        </Link>
      </CCol>
      <br />
      <CCol xs={12}>
        <CCard className='mb-4'>
          <CCardHeader>
            <strong>{problem ? 'Editar problema' : 'Nuevo problema'}</strong>
          </CCardHeader>
          <CCardBody>
            <CInputGroup className='mb-3'>
              <CInputGroupText required>Título del problema</CInputGroupText>
              <ReactQuill
                style={{ paddingBottom: '50px', marginLeft: '20px', width: '70%' }}
                theme='snow'
                modules={modules}
                value={titleStyle}
                onChange={handleTitleChange}
              />
            </CInputGroup>
            <DimensionsDropdown
              setSelectedDimension={setSelectedDimension}
              problem={problem}
              selectedDimension={selectedDimension}
            />
            <br />
            <ContentsForm element={problem} setContents={setContents} setValidUrl={setValidUrl} />
            {error && <CLabel>Hubo un error al intentar guardar</CLabel>}
            <CButton
              style={{ float: 'right' }}
              onClick={addProblem}
              disabled={!canSave}
              type='button'
              color='danger'
              id='button-addon1'>
              {problem ? 'Guardar' : 'Crear problema'}
            </CButton>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default DimensionsForm;
