import React, {useEffect, useState, useRef} from 'react'
import { CButton, CSpinner ,CCard, CRow, CCol, CCardHeader, CCardBody, CListGroup, CListGroupItem, CInput } from '@coreui/react'
import { Link, useHistory } from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import CIcon from '@coreui/icons-react'
const url = process.env.REACT_APP_BACK_URL;

const Dimensions = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const dimensions = useSelector((state) => state.dimensions.data);
    const [stateDimensions, setStateDimensions] = useState([])
    const [isLoading, setLoading] = useState(false)
    const initialRender = useRef(true)

    const remove = (dimension) => {
      setLoading(true);
      fetch(`${url}/prv/elements/dimensions/` + dimension.slug, {
        headers: {
          'Authorization': 'bearer ' + localStorage.getItem('inecip_access_token'),
          'Accept': 'application/json',
          'Content-Type': 'application/json'             
        }, method: "DELETE" })
      .then(() => dispatch({type: 'removeDimension', dimension}))
    }

    const filterDimensions = (e) => {
      setStateDimensions(dimensions.filter((dimensions) => dimensions.title.includes(e.target.value)))
    }

    const findElementPosition = (stateElement) =>
      dimensions.indexOf(dimensions.find(el => el.slug === stateElement.slug))

    useEffect(() => {
      setLoading(true)
      fetch(`${url}/prv/users`, {
        headers: {
          'Authorization': 'bearer ' + localStorage.getItem('inecip_access_token'),
          'Accept': 'application/json',
          'Content-Type': 'application/json'}})
          .then(() => {      
            fetch(`${url}/pub/elements/dimensions`)
            .then(response => response.json())
            .then(response => dispatch({type: 'setDimensions', data: response.elements[0].tree_elements_complete}))
            .then(() => initialRender.current = false)
          })
      .catch(() => history.push('/'))   
    },[])

    useEffect(() => {
      if (!initialRender.current) {
        setStateDimensions(dimensions);
        setLoading(false);
      }
    }, [dimensions])

    return(
        <>
          {isLoading ? <CSpinner style={{display:'block', margin: '0 auto'}}/> : 
        <CRow>
        <CCol xs={12}>
        <CCard className="mb-4">
            <>
              <CCardHeader>
                <strong style={{verticalAlign:'sub'}}>Listado de dimensiones</strong>
                {dimensions && dimensions.length > 0 && <CInput
                    type="dimensionsFilter"
                    id="dimensions"
                    name="dimensions"
                    placeholder="Filtrar dimensiones"
                    className="dimensionsFilter"
                    onChange={filterDimensions}
                  />
                }
                <Link to="/dimensions/new">
                    <CButton type="button" color="danger" id="button-addon1" style={{float:'right'}}>
                        Nueva dimension
                    </CButton>
                </Link>
              </CCardHeader>
              {stateDimensions.length > 0 &&
                <CCardBody>
                    <CListGroup>
                        {stateDimensions.map((dimension, index) => (
                            <div style={{position: 'relative'}}>
                              <Link to={"/dimensions/" + findElementPosition(dimension)}>
                                  <CListGroupItem style={{backgroundColor: dimension.color}} key={index}>
                                      <span style={{textDecoration: 'none', color: 'black'}}>{dimension.title}</span>
                                  </CListGroupItem>
                              </Link>
                              <div onClick={() => {if (window.confirm(`Seguro que queres eliminar la dimension "${dimension.title}" ?`)) remove(dimension)}} className="dimensionRemove">
                                <CIcon name='cil-trash' style={{color: 'black'}}/>
                              </div>
                            </div>
                        ))}
                    </CListGroup>
                </CCardBody>}
            </>
          </CCard>
        </CCol>
        </CRow>
            }
    </>
    )

}

export default Dimensions;