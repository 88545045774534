import './Axis.scss'

import React, { useEffect, useState, useRef } from 'react';
import {
  CButton,
  CSpinner,
  CCard,
  CRow,
  CCol,
  CCardHeader,
  CCardBody,
  CListGroup,
  CListGroupItem,
  CInput,
  CContainer
} from '@coreui/react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CIcon from '@coreui/icons-react';
const url = process.env.REACT_APP_BACK_URL;

const Axis = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const axes = useSelector((state) => state.axes.data);
  const [stateAxes, setStateAxes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const initialRender = useRef(true);

  const remove = (axis) => {
    setLoading(true);
    fetch(`${url}/prv/elements/axes/` + axis.slug, {
      method: 'DELETE',
      headers: {
        Authorization: 'bearer ' + localStorage.getItem('inecip_access_token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(() => dispatch({ type: 'removeAxis', axis }));
  };

  const filterAxes = (e) => {
    setStateAxes(axes.filter((axis) => axis.title.toLowerCase().includes(e.target.value.toLowerCase())));
  };

  const findElementPosition = (stateElement) => axes.indexOf(axes.find((el) => el.slug === stateElement.slug));

  useEffect(() => {
    setLoading(true);
    fetch(`${url}/prv/users`, {
      headers: {
        Authorization: 'bearer ' + localStorage.getItem('inecip_access_token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        fetch(`${url}/pub/elements/axes`)
          .then((response) => response.json())
          .then((response) => dispatch({ type: 'setAxes', data: response.elements[0].tree_elements_complete }))
          .then(() => (initialRender.current = false));
      })
      .catch(() => history.push('/'));
  }, []);

  useEffect(() => {
    if (!initialRender.current) {
      setStateAxes(axes);
      setLoading(false);
    }
  }, [axes]);

  const handleAxisRemove = axis => () => {
    if (window.confirm(`Seguro que queres eliminar el eje "${axis.title}" ?`)) remove(axis);
  }

  if (isLoading) {
    return <CSpinner style={{ display: 'block', margin: '0 auto' }} />;
  }

  console.log({axes})

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className='mb-4'>
          <CCardHeader>
            <strong style={{ verticalAlign: 'sub' }}>Listado de ejes</strong>
            {axes && axes.length > 0 && (
              <CInput
                type='axesFilter'
                id='axes'
                name='axes'
                placeholder='Filtrar ejes'
                className='axesFilter'
                onChange={filterAxes}
              />
            )}
            <Link to='/axes/new'>
              <CButton type='button' color='danger' id='button-addon1' style={{ float: 'right' }}>
                Nuevo eje
              </CButton>
            </Link>
          </CCardHeader>
          {stateAxes.length > 0 && (
            <CCardBody>
              <CListGroup>
                {stateAxes.map((axis) => (
                  <CListGroupItem
                    key={`${axis.slug}-${axis.createdAt}-${axis.color}`}
                    style={{ backgroundColor: axis.color }}>
                    <CContainer fluid>
                      <CRow>
                        <CCol xs={11}>
                          <Link style={{ color: 'white', maxWidth: '80%' }} to={`/axes/${findElementPosition(axis)}`}>
                            <span style={{ maxWidth: '80%', display: 'flex', alignItems: 'center' }}>{axis.title}</span>
                          </Link>
                        </CCol>
                        <CCol>
                          <div onClick={handleAxisRemove(axis)} className='axisRemove float-right'>
                            <CIcon name='cil-trash' style={{ color: 'black' }} />
                          </div>
                        </CCol>
                      </CRow>
                    </CContainer>
                  </CListGroupItem>
                ))}
              </CListGroup>
            </CCardBody>
          )}
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Axis;
