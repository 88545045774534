import React, { useEffect, useState, useMemo } from 'react';
import { CCard, CButton, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import ReactQuill, { Quill } from 'react-quill';
import '../contentsForm/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';

Quill.register('modules/imageResize', ImageResize);
const Font = Quill.import('formats/font');
Font.whitelist = ['Roboto', 'Raleway', 'Alegreya-Sans'];
Quill.register(Font, true);

const url = process.env.REACT_APP_BACK_URL;

const AboutUs = (props) => {
  const [feature, setFeature] = useState('');

  const updateAboutUs = () => {
    fetch(`${url}/prv/aboutuscontents`, {
      method: 'PUT',
      headers: {
        Authorization: 'bearer ' + localStorage.getItem('inecip_access_token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(feature),
    }).then(() => {
      alert('Se actualizo con exito!');
    });
  };

  const modules = useMemo(
    () => ({
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize'],
      },
      toolbar: {
        container: [
          [{ header: [1, 2, false] }, { font: Font.whitelist }],
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['image', 'link'],
        ],
        handlers: {
          image: imgHandler,
        },
      },
    }),
    []
  );

  function imgHandler() {
    const tooltip = this.quill.theme.tooltip;
    const originalSave = tooltip.save;
    const originalHide = tooltip.hide;

    tooltip.save = function () {
      const range = this.quill.getSelection(true);
      const value = this.textbox.value;
      if (value) {
        this.quill.insertEmbed(range.index, 'image', value, 'user');
      }
    };
    // Called on hide and save.
    tooltip.hide = function () {
      tooltip.save = originalSave;
      tooltip.hide = originalHide;
      tooltip.hide();
    };
    tooltip.edit('image');
    tooltip.textbox.placeholder = 'Dirección URL';
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url + '/pub/aboutuscontents');
        const json = await response.json();
        setFeature(json.about_us_content);
      } catch (error) {
        console.log('error', error);
      }
    };
    fetchData();
  }, []);

  const changeExistingContent = (value, id) => {
    const aboutUs = {
      content: value,
      id: 1,
    };
    setFeature(aboutUs);
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className='mb-4 mt-0'>
          <CCardHeader>
            <strong>{'Sobre nosotros'}</strong>
          </CCardHeader>
          <CCardBody>
            <ReactQuill
              style={{ paddingBottom: '50px', marginLeft: '20px', width: '70%' }}
              theme='snow'
              modules={modules}
              value={feature ? feature.content : ''}
              onChange={(value) => changeExistingContent(value, feature.id)}
            />
            <CButton style={{ float: 'right' }} onClick={updateAboutUs} type='button' color='danger' id='button-addon1'>
              {'Actualizar'}
            </CButton>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default AboutUs;
