import { createStore, combineReducers } from 'redux'
import axes from './reducers/axes'
import ui from './reducers/ui'
import dimensions from './reducers/dimensions'
import problems from './reducers/problems'
import resources from './reducers/resources'
import users from './reducers/users'

const store = createStore(
  combineReducers({
    ui,
    axes,
    dimensions,
    problems,
    resources,
    users
  }), 
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)


export default store