import React, { useEffect, useMemo, useState } from 'react';
import {
  CCard,
  CSpinner,
  CButton,
  CLabel,
  CInput,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CInputGroup,
  CInputGroupText,
  CTextarea,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import ContentsForm from '../../contentsForm/ContentsForm';
import ReactQuill, { Quill } from 'react-quill';
import { cleanLinebreaks } from 'src/components/utils';

const Font = Quill.import('formats/font');
Font.whitelist = ['Roboto', 'Raleway', 'Alegreya-Sans'];
Quill.register(Font, true);

const url = process.env.REACT_APP_BACK_URL;

const AxisForm = ({ match }) => {
  const history = useHistory();
  const allAxes = useSelector((state) => state.axes.data);
  const axis = useSelector(({ axes }) =>
    match.params.axisId && axes?.data ? axes?.data[match.params.axisId] : undefined
  );
  const dispatch = useDispatch();

  const [title, setTitle] = useState(axis?.title ?? '');
  const [titleStyle, setTitleStyle] = useState(axis?.title_style ?? title);
  const [description, setDescription] = useState(axis?.description ?? '');
  const [color, setColor] = useState(axis?.color ?? '');
  const [canSave, setCanSave] = useState(!!axis);
  const [contents, setContents] = useState(axis?.contents ?? []);
  const [validUrl, setValidUrl] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (title && color && validUrl) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [title, color, description, validUrl]);

  const addAxis = () => {
    setSaving(true);
    let axisToAdd = {
      element: { title, title_style: titleStyle, description, color, order: allAxes.length + 1 },
      contents,
    };
    let routeParam = axis ? `/${axis.slug}` : '';
    if (canSave) {
      fetch(`${url}/prv/elements/axes` + routeParam, {
        method: axis ? 'PUT' : 'POST',
        headers: {
          Authorization: 'bearer ' + localStorage.getItem('inecip_access_token'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(axisToAdd),
      })
        .then(() => {
          dispatch({ type: 'addAxis', axis: axisToAdd });
        })
        .then(() => {
          setSaving(false);
          history.push('/axes');
        })
        .catch(() => setError(true));
    }
  };

  const modules = useMemo(
    () => ({
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize'],
      },
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: Font.whitelist }],
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['image', 'link'],
        ],
      },
    }),
    []
  );

  if (!allAxes) {
    history.push('/axes');
    return null;
  }

  const handleTitleChange = (value, delta, source, editor) => {
    setTitleStyle(value);
    setTitle(cleanLinebreaks(editor.getText()));
  };

  return (
    <CRow>
      <CCol xs={12}>
        <Link to='/axes'>
          <CButton type='button' color='info' id='button-addon1' style={{ marginBottom: '30px' }}>
            Volver
          </CButton>
        </Link>
      </CCol>
      <br />
      <CCol xs={12}>
        <CCard className='mb-4'>
          <CCardHeader>
            <strong>{axis ? 'Editar eje' : 'Nuevo eje'}</strong>
          </CCardHeader>
          <CCardBody>
            <CInputGroup className='mb-3'>
              <CInputGroupText required>Título del eje</CInputGroupText>
              <ReactQuill
                style={{ paddingBottom: '50px', marginLeft: '20px', width: '70%' }}
                theme='snow'
                modules={modules}
                value={titleStyle}
                onChange={handleTitleChange}
              />
            </CInputGroup>
            <br />
            <CLabel required>Seleccione un color</CLabel>
            <CInput
              list='colores'
              type='color'
              id='exampleColorInput'
              defaultValue={color}
              title='Seleccione un color'
              onChange={(e) => setColor(e.target.value)}
              style={{ width: '100px' }}
            />
            <datalist id='colores'>
              <option>#c53254</option>
              <option>#9a123e</option>
              <option>#631d32</option>
              <option>#450033</option>
              <option>#5e6067</option>
              <option>#4a4b52</option>
              <option>#34353a</option>
              <option>#1a1a1f</option>
            </datalist>
            <br />
            <ContentsForm element={axis} setContents={setContents} setValidUrl={setValidUrl} />
            {error && <CLabel>Hubo un error al intentar guardar</CLabel>}
            <CButton
              style={{ float: 'right' }}
              onClick={addAxis}
              disabled={!canSave}
              type='button'
              color='danger'
              id='button-addon1'>
              {!saving ? axis ? 'Guardar' : 'Crear eje' : <CSpinner size='sm' />}
            </CButton>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default AxisForm;
