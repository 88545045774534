export default (usersState = [], actionParams) => {
    switch (actionParams.type) {
      case 'setUsers':
        return {...usersState, data: actionParams.data}
      case 'addUser':
        return { ...usersState, data: [...usersState.data, actionParams.user] }      
      case 'removeUser':
          let newUsers = usersState.data;
          newUsers = newUsers.filter(user => user.email !== actionParams.user.email)
          return { ...usersState, data: newUsers }  
      default:
        return usersState
    }
  }