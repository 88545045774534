import React, {useEffect, useState, useRef} from 'react'
import { CButton, CSpinner ,CCard, CRow, CCol, CCardHeader, CCardBody, CListGroup, CListGroupItem, CInput } from '@coreui/react'
import { Link, useHistory } from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import CIcon from '@coreui/icons-react'
const url = process.env.REACT_APP_BACK_URL;

const Problems = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const resources = useSelector((state) => state.resources.data);
    const [stateResources, setStateResources] = useState([])
    const [isLoading, setLoading] = useState(false)
    const initialRender = useRef(true)

    const remove = (resource) => {
      setLoading(true);
      fetch(`${url}/prv/elements/resources/` + resource.slug, {
        headers: {
          'Authorization': 'bearer ' + localStorage.getItem('inecip_access_token'),
          'Accept': 'application/json',
          'Content-Type': 'application/json'         
        },
        method: 'DELETE'        
      })
      .then(() => dispatch({type: 'removeResource', resource}))
    }

    const filterResources = (e) => {
      setStateResources(resources.filter((resources) => resources.title.includes(e.target.value)))
    }

    useEffect(() => {
      setLoading(true)
      fetch(`${url}/prv/users`, {
        headers: {
          'Authorization': 'bearer ' + localStorage.getItem('inecip_access_token'),
          'Accept': 'application/json',
          'Content-Type': 'application/json'}})
          .then(() => {      
            fetch(`${url}/pub/elements/resources`)
            .then(response => response.json())
            .then(response => dispatch({type: 'setResources', data: response.elements[0].tree_elements_complete}))
            .then(() => initialRender.current = false)
          })
      .catch(() => history.push('/'))
    },[])

    const findElementPosition = (stateElement) =>
      resources.indexOf(resources.find(el => el.slug === stateElement.slug))
    

    useEffect(() => {
      if (!initialRender.current) {
        setStateResources(resources);
        setLoading(false);
      }
    }, [resources])

    return(
        <>
          {isLoading ? <CSpinner style={{display:'block', margin: '0 auto'}}/> : 
        <CRow>
        <CCol xs={12}>
        <CCard className="mb-4">
            <>
              <CCardHeader>
                <strong style={{verticalAlign:'sub'}}>Listado de insumos</strong>
                {resources && resources.length > 0 && <CInput
                    type="dimensionsFilter"
                    id="dimensions"
                    name="dimensions"
                    placeholder="Filtrar Insumos"
                    className="dimensionsFilter"
                    onChange={filterResources}
                  />
                }
                <Link to="/resources/new">
                    <CButton type="button" color="danger" id="button-addon1" style={{float:'right'}}>
                        Nuevo insumo
                    </CButton>
                </Link>
              </CCardHeader>
              {stateResources.length > 0 &&
                <CCardBody>
                    <CListGroup>
                        {stateResources.map((resource, index) => (
                            <div style={{position: 'relative'}} key={index + resource.id}>
                              <Link to={"/resources/" + findElementPosition(resource)}>
                                  <CListGroupItem style={{backgroundColor: resource.color}} key={resource.id}>
                                      <span style={{textDecoration: 'none', color: 'black'}}>{resource.title}</span>
                                  </CListGroupItem>
                              </Link>
                              <div onClick={() => {if (window.confirm(`Seguro que queres eliminar el insumo "${resource.title}" ?`)) remove(resource)}} className="dimensionRemove">
                                <CIcon name='cil-trash' style={{color: 'black'}}/>
                              </div>
                            </div>
                        ))}
                    </CListGroup>
                </CCardBody>}
            </>
          </CCard>
        </CCol>
        </CRow>
            }
    </>
    )

}

export default Problems;