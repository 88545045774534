export default (resourcesState = [], actionParams) => {
    switch (actionParams.type) {
      case 'setResources':
        return {...resourcesState, data: actionParams.data}
      case 'addResource':
        if (actionParams.resource.index) {
          let newResources = resourcesState.data;
          newResources[actionParams.resource.index] = actionParams.resource;
          return { ...resourcesState, data: newResources }  
        }
        return { ...resourcesState, data: [...resourcesState.data, actionParams.resource] }      
      case 'removeResource':
          let newResources = resourcesState.data;
          newResources = newResources.filter(resource => resource.title !== actionParams.resource.title)
          return { ...resourcesState, data: newResources }  
      default:
        return resourcesState
    }
  }