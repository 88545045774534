import React, { lazy, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  CCard,
  CCardHeader,
  CSpinner,
  CCol,
  CRow,
  CImg
} from '@coreui/react'
const url = process.env.REACT_APP_BACK_URL;


const Dashboard = () => {

  const history = useHistory()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetch(`${url}/prv/users`, {
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem('inecip_access_token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(() => setLoading(false))
      .catch(() => { history.push('/') })
  }, [])

  if (loading) {
    return <CSpinner style={{ display: 'block', margin: '0 auto' }} />
  }

  return (
    <>
      <CRow>
        <CCol xs>
          <CCard className="mb-4">
            <CCardHeader>
              <>
                <br />
                <div>
                  {<CImg
                    style={{ margin: '0 auto', display: 'block' }}
                    src={'logo.png'}
                    className="align-top"
                    alt="CoreuiVue"
                    height='auto'
                    width='70%'
                  />}
                </div>
                <br />
              </>
            </CCardHeader>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default Dashboard
