import React, {useEffect, useRef, useState} from 'react';
import { CSpinner, CInputGroup } from '@coreui/react'
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const url = process.env.REACT_APP_BACK_URL;
const AxesDropdown = ({resource, setSelectedProblem, selectedProblem}) => {

    const dispatch = useDispatch();

    const problems = useSelector((state) => state.problems.data);
    const [stateProblems, setStateDimensions] = useState([])
    const [showDropdown, setDropdown] = useState(false)
    const [inputValue, setInputValue] = useState(resource ? resource.axis : '')
    const [isLoading, setLoading] = useState(false)
    const initialRender = useRef(true)

    useEffect(() => {
        setLoading(true)
        fetch(`${url}/pub/elements/problems`)
        .then(response => response.json())
        .then(response => dispatch({type: 'setProblems', data: response.elements[0].tree_elements_complete}))
        .then(() => initialRender.current = false)
    },[])

    useEffect(() => {
        if (!initialRender.current) {
            setStateDimensions(problems)
            setLoading(false);
        }
    }, [problems])

    const defaultProblems = () => {
        return stateProblems.find((problem) => problem.slug === (selectedProblem && selectedProblem.slug ? selectedProblem.slug : selectedProblem))
    }

    if (isLoading) {
        return(
            <CSpinner style={{display:'block', margin: '0 auto'}}/>
        )
    } else {
        return  (
            <div>
                <br/>
                <CInputGroup className={"autoCompleteContainer"}>
                    <Autocomplete
                        size="small"
                        value={defaultProblems()}
                        options={problems}
                        renderInput={(params) => <TextField {...params} label="Problema al que pertenece" variant="outlined" />}
                        style={{ width: 300, height: 50 }}
                        getOptionLabel={(option) => option.title}
                        isSearchable={true}
                        getOptionSelected={(option) => option.slug}
                        onChange={(e, selected) => setSelectedProblem(selected.slug)}
                    />
                </CInputGroup>
            </div>
            )
    }
}

export default AxesDropdown;
