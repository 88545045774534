export default (dimensionsState = [], actionParams) => {
    switch (actionParams.type) {
      case 'setDimensions':
        return {...dimensionsState, data: actionParams.data}
      case 'addDimension':
        if (actionParams.dimension.index) {
          let newDimensions = dimensionsState.data;
          newDimensions[actionParams.dimension.index] = actionParams.dimension;
          return { ...dimensionsState, data: newDimensions }  
        }
        return { ...dimensionsState, data: [...dimensionsState.data, actionParams.dimension] }      
      case 'removeDimension':
          let newDimensions = dimensionsState.data;
          newDimensions = newDimensions.filter(dimension => dimension.title !== actionParams.dimension.title)
          return { ...dimensionsState, data: newDimensions }  
      default:
        return dimensionsState  
    }
  }