import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  CButton,
  CCol,
  CRow,
  CInputGroup,
  CDropdown,
  CDropdownMenu,
  CDropdownItem,
  CDropdownToggle,
  CInput,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import validator from 'validator';
import ReactQuill, { Quill } from 'react-quill';
import './quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';

Quill.register('modules/imageResize', ImageResize);
const Font = Quill.import('formats/font');
Font.whitelist = ['Roboto', 'Raleway', 'Alegreya-Sans'];
Quill.register(Font, true);

const ContentsForm = ({ element, setContents, setValidUrl }) => {
  const [currentContents, setCurrentContents] = useState(
    element && element.contents_with_type ? element.contents_with_type : []
  );
  const [isAdding, setAdding] = useState();
  const [newContent, setNewContent] = useState({
    content_type: '',
    content: '',
    order: currentContents.length + 1,
  });

  function imgHandler() {
    const tooltip = this.quill.theme.tooltip;
    const originalSave = tooltip.save;
    const originalHide = tooltip.hide;

    tooltip.save = function () {
      const range = this.quill.getSelection(true);
      const value = this.textbox.value;
      if (value) {
        this.quill.insertEmbed(range.index, 'image', value, 'user');
      }
    };
    // Called on hide and save.
    tooltip.hide = function () {
      tooltip.save = originalSave;
      tooltip.hide = originalHide;
      tooltip.hide();
    };
    tooltip.edit('image');
    tooltip.textbox.placeholder = 'Dirección URL';
  }

  const modules = useMemo(
    () => ({
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize'],
      },
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: Font.whitelist }],
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['image', 'link'],
        ],
        handlers: {
          image: imgHandler,
        },
      },
    }),
    []
  );

  //validates existing contents are links of type
  const validateAllContents = () =>
    currentContents.every(
      (content) => content.content_type && (content.content_type == 'text' || validator.isURL(content.content))
    );
  //validates new content is not being created or it's ready

  useEffect(() => {
    //all initial contents are valid
    setCurrentContents(
      currentContents.map((content) => {
        content.content_type = content.content_type_slug.slug;
        return content;
      })
    );
    setValidUrl(validateAllContents());
  }, []);

  useEffect(() => {
    setContents(currentContents);
  }, [currentContents]);

  const removeContent = (i) => {
    setCurrentContents((oldContents) =>
      oldContents.filter((content, index) => index !== i)
    );
  };

  const addNewContent = () => {
    setCurrentContents((oldContents) => [...oldContents, newContent]);
    setNewContent({
        content_type: '',
        content: '',
        order: currentContents.length + 1,
    });
    setAdding(false);
  };

  const contentTypeTranslator = (contentType) => {
    switch (contentType) {
      case 'texto':
        return 'text';
      case 'Link de archivo':
        return 'filelink';
      default:
        return contentType;
    }
  };

  const typeToLabel = (type) => {
    switch (type) {
      case 'text':
        return 'texto';
      case 'filelink':
        return 'Link de archivo';
      default:
        return type;
    }
  };

  const changeExistingContent = (value, type, i) => {
    if (typeof i !== 'undefined') {
      let auxCurrentContents = currentContents;
      if (type === 'type') {
        auxCurrentContents[i].content_type = contentTypeTranslator(value);
      } else {
        auxCurrentContents[i][type] = value;
      }
      setCurrentContents([...auxCurrentContents]);
    } else {
      let auxNewContent = newContent;
      if (type === 'type') {
        auxNewContent.content_type = contentTypeTranslator(value);
      } else {
        auxNewContent[type] = value;
      }
      setNewContent({ ...auxNewContent });
    }
    setValidUrl(validateAllContents());
  };

  const contentForm = (elementContentType, elementContentLink, i) => {
    return (
      <CRow className='mb-3'>
        <CInputGroup className='autoCompleteContainer'>
          <CDropdown style={{ display: 'flex', alignSelf: 'center' }}>
            <CDropdownToggle style={!elementContentType ? { borderColor: 'red' } : {}} caret color='info'>
              {typeToLabel(elementContentType) || 'Tipo de contenido'}
            </CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem header>Tipo de contenido</CDropdownItem>
              <CDropdownItem onClick={() => changeExistingContent('texto', 'type', i)}>texto libre</CDropdownItem>
              <CDropdownItem onClick={() => changeExistingContent('audio', 'type', i)}>Audio</CDropdownItem>
              <CDropdownItem onClick={() => changeExistingContent('video', 'type', i)}>Video</CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
          {elementContentType !== 'text' ? (
            <CInput
              style={
                !validator.isURL(elementContentLink)
                  ? { marginLeft: '10px', borderColor: 'red', marginRight: 30 }
                  : { marginLeft: '10px', marginRight: 30 }
              }
              aria-label='Link  '
              aria-describedby='basic-addon1'
              defaultValue={elementContentLink}
              onChange={(e) => changeExistingContent(e.target.value, 'content', i)}
            />
          ) : (
            <ReactQuill
              style={{ paddingBottom: '50px', marginLeft: '20px', width: '70%' }}
              theme='snow'
              modules={modules}
              value={elementContentLink}
              onChange={(value) => changeExistingContent(value, 'content', i)}
            />
          )}
          {typeof i == 'undefined' && (
            <CButton
              disabled={
                !newContent.content_type ||
                !newContent.content ||
                (newContent.content_type != 'text' && !validator.isURL(newContent.content))
              }
              onClick={addNewContent}
              color='warning'
              style={{ marginLeft: 'auto', alignSelf: 'center' }}>
              <CIcon name='cil-x' style={{ color: 'black', transform: `rotate(${45}deg)` }} />
            </CButton>
          )}
          {typeof i !== 'undefined' && (
            <CButton
              alt='Eliminar'
              color='secondary'
              style={{ marginLeft: 'auto', alignSelf: 'center' }}
              onClick={() => removeContent(i)}>
              <CIcon name='cil-trash' style={{ color: 'black' }} />
            </CButton>
          )}
        </CInputGroup>
      </CRow>
    );
  };
  console.log(currentContents);
  return (
    <>
      <br />
      <br />
      <h5 className='mb-4'>Contenidos</h5>
      {currentContents.map((content, i) => (
        <CCol xs={11} key={`${i}${content.content_type}`}>
          {contentForm(content.content_type, content.content, i)}
        </CCol>
      ))}
      {isAdding && <CCol xs={11}>{contentForm(newContent.content_type, newContent.content)}</CCol>}
      <br />
      <CCol xs={8}>
        <CRow>
          <CButton onClick={() => setAdding(isAdding ? false : true)} color='success'>
            {isAdding ? 'Cancelar' : 'Agregar otro'}
          </CButton>
        </CRow>
      </CCol>
    </>
  );
};

export default ContentsForm;
