import React, { useEffect, useState } from 'react'
import { CButton, CSpinner, CCard, CRow, CCol, CCardHeader, CCardBody, CListGroup, CListGroupItem } from '@coreui/react'
import { Link } from 'react-router-dom'
import CIcon from '@coreui/icons-react'
const url = process.env.REACT_APP_BACK_URL;

const Trainings = () => {
  const [trainings, setTrainings] = useState([])
  const [isLoading, setLoading] = useState(false)

  const remove = (training) => {
    fetch(`${url}/prv/trainings/` + training.uuid, {
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem('inecip_access_token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'DELETE'
    })
      .then(alert("Se Borro exitosamente!"))
      .then(setTrainings(trainings.filter(tr => tr.uuid !== training.uuid)))
  }

  const findElementPosition = (stateElement) =>
    trainings.indexOf(trainings.find(el => el.uuid === stateElement.uuid))

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url + "/pub/trainings");
        const json = await response.json();
        setTrainings(json.trainings);
        setLoading(false);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {isLoading ? <CSpinner style={{ display: 'block', margin: '0 auto' }} /> :
        <CRow>
          <CCol xs={12}>
            <CCard className="mb-4">
              <>
                <CCardHeader>
                  <strong style={{ verticalAlign: 'sub' }}>Listado de capacitaciones</strong>
                  <Link to="/trainings/new">
                    <CButton type="button" color="danger" id="button-addon1" style={{ float: 'right' }}>
                      Nueva capacitación
                    </CButton>
                  </Link>
                </CCardHeader>
                {trainings.length > 0 &&
                  <CCardBody>
                    <CListGroup>
                      {trainings.map((training, index) => (
                        <div style={{ position: 'relative' }}>
                          <Link to={"/trainings/" + training.uuid}>
                            <CListGroupItem style={index % 2 === 0 ? { background: 'rgb(100, 29, 47)' } : {}} key={index}>
                              <span style={index % 2 === 0 ? { textDecoration: 'none', color: 'white', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inherit', paddingRight: '6%' } : { color: 'black',whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inherit', paddingRight: '6%' }}>{training.content.replace(/(<([^>]+)>)/gi, "")}</span>
                            </CListGroupItem>
                          </Link>
                          <div onClick={() => { if (window.confirm(`Seguro que queres eliminar la capacitación "${training.content}" ?`)) remove(training) }} className="dimensionRemove">
                            <CIcon name='cil-trash' style={{ color: 'black' }} />
                          </div>
                        </div>
                      ))}
                    </CListGroup>
                  </CCardBody>}
              </>
            </CCard>
          </CCol>
        </CRow>
      }
    </>
  )

}

export default Trainings;
