import React, { useEffect, useRef, useState } from 'react';
import { CSpinner, CInputGroup } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const url = process.env.REACT_APP_BACK_URL;

const AxesDropdown = ({ dimension, setSelectedAxis, selectedAxis }) => {
  const dispatch = useDispatch();

  const axes = useSelector((state) => state.axes.data ?? []);
  const [stateAxes, setStateAxes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const initialRender = useRef(true);

  useEffect(() => {
    setLoading(true);
    fetch(`${url}/pub/elements/axes`)
      .then((response) => response.json())
      .then((response) => dispatch({ type: 'setAxes', data: response.elements[0].tree_elements_complete }))
      .then(() => (initialRender.current = false));
  }, []);

  useEffect(() => {
    if (!initialRender.current) {
      setStateAxes(axes);
      setLoading(false);
    }
  }, [axes]);

  const defaultAxis = () => {
    return stateAxes.find(
      (axis) => axis.slug === (selectedAxis && selectedAxis.slug ? selectedAxis.slug : selectedAxis)
    );
  };

  if (isLoading) {
    return <CSpinner style={{ display: 'block', margin: '0 auto' }} />;
  }

  return (
    <div>
      <br />
      <CInputGroup className={'autoCompleteContainer'}>
        <Autocomplete
          size='small'
          value={defaultAxis()}
          renderInput={(params) => <TextField {...params} label='Eje al que pertenece' variant='outlined' />}
          options={axes}
          style={{ width: 300, height: 50 }}
          getOptionLabel={option => option.title}
          renderOption={(option) => <span dangerouslySetInnerHTML={{ __html: option.title }} />}
          isSearchable={true}
          getOptionSelected={(option) => option.slug}
          onChange={(e, selected) => setSelectedAxis(selected.slug)}
        />
      </CInputGroup>
    </div>
  );
};

export default AxesDropdown;
