import React, {useEffect, useState} from 'react'
import { CCard, CSpinner, CButton, CInput,CCardBody, CCardHeader, CCol, CRow, CInputGroup, CInputGroupText } from '@coreui/react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from "react-router-dom";

const url = process.env.REACT_APP_BACK_URL;


const DimensionsForm = ({ match }) => {

///////dimensions
    const allUsers = useSelector((state) => state.users?.data)
    const history = useHistory();
    const user = useSelector(({ users }) =>
      match.params.userId && users ? users.data?.find((user) => user.id === match.params.userId) : undefined
    );
	const [name, setName] = useState(user ? user.firstname : '')
	const [surname, setSurname] = useState(user ? user.lastname : '')
	const [email, setEmail] = useState(user ? user.email : '')
	const [canSave, setCanSave] = useState(!!user)
    const [saving, setSaving] = useState(false)
	const dispatch = useDispatch();

	useEffect(() => {
		if (name && surname && email && validateEmail(email)) { //&& selectedAxis && validUrl) {
			setCanSave(true)
		} else {
            setCanSave(false)
		}
	}, [name, surname, email]) //description, selectedAxis, validUrl])

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

	const addUser = () => {
        setSaving(true)
        let routeParam = user ? `/${user.uuid}` : ''
        let userToAdd = {firstname: name, lastname: surname, email}//title, description, axis: selectedAxis, contents}
		if (canSave) {
            fetch(`${url}/prv/users` + routeParam,
                {
                    method: user ? "PUT" : "POST",
                    headers: {
                        'Authorization': 'bearer ' + localStorage.getItem('inecip_access_token'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                },
                body: JSON.stringify(user ? userToAdd : {...userToAdd, password: '12345678'})
            })
            .then( () => {
                    dispatch({type:'addUser', user: userToAdd})
            })
            .then(() => {
                setSaving(false);
                history.push("/users")
            })
		}
	}

    if (!allUsers) {
        history.push("/users")
        return null
    }

    return (
        <CRow>
            <CCol xs={12}>
            <Link to='/users'>
                <CButton type="button" color="info" id="button-addon1" style={{marginBottom:'30px'}}>
                Volver
                </CButton>
            </Link>
            </CCol>
            <br/>
            <CCol xs={12}>
            <CCard className="mb-4">
                <CCardHeader>
                <strong>{user ? 'Editar "' + user.firstname + ' ' + user.lastname + '"' : 'Nuevo usuario'}</strong>
                </CCardHeader>
                <CCardBody>
                <CInputGroup className="mb-3">
                <CInputGroupText required>Nombre</CInputGroupText>
                    <CInput
                        defaultValue={name}
                        aria-label="Nombre  "
                        aria-describedby="basic-addon1"
                        onChange={(e) => setName(e.target.value)}
                    />
                </CInputGroup>
                <CInputGroup className="mb-3">
                    <CInputGroupText required>Apellido</CInputGroupText>
                    <CInput
                        defaultValue={surname}
                        aria-label="Apellido  "
                        aria-describedby="basic-addon1"
                        onChange={(e) => setSurname(e.target.value)}
                    />
                </CInputGroup>
                <CInputGroup className="mb-3">
                    <CInputGroupText required>Email</CInputGroupText>
                    <CInput
                        style={email && !validateEmail(email) ? {borderColor: 'red'} : {}}
                        defaultValue={email}
                        aria-label="Email"
                        aria-describedby="basic-addon1"
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                    />
                </CInputGroup>
                <br/>
                <CButton style={{float: 'right'}} onClick={addUser} disabled={!canSave} type="button" color="danger" id="button-addon1">
                    {!saving ? user ? 'Guardar' : 'Crear usuario' : <CSpinner size="sm"/>}
                </CButton>
                </CCardBody>
            </CCard>
            </CCol>
        </CRow>
        )
    }

export default DimensionsForm
