import React, { useEffect, useState, useMemo } from 'react';
import {
  CCard,
  CButton,
  CInput,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CInputGroup,
  CInputGroupText,
} from '@coreui/react';
import { Link, useHistory } from 'react-router-dom';
import ReactQuill, { Quill } from 'react-quill';
import '../contentsForm/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';

Quill.register('modules/imageResize', ImageResize);
const Font = Quill.import('formats/font');
Font.whitelist = ['Roboto', 'Raleway', 'Alegreya-Sans'];
Quill.register(Font, true);

const url = process.env.REACT_APP_BACK_URL;

const TrainingForm = (props) => {
  ///////training
  const history = useHistory();
  const trainingId = props.match.params.trainingId;
  const [training, setTraining] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState('');
  const [order, setOrder] = useState('');
  const [canSave, setCanSave] = useState(!!training);

  useEffect(() => {
    const fetchData = async () => {
      if (trainingId !== undefined) {
        try {
          const response = await fetch(url + '/pub/trainings/' + trainingId);
          const json = await response.json();
          setOrder(json.uuid.order);
          setImage(json.uuid.image);
          setContent(json.uuid.content);
          setTraining(json.uuid);
        } catch (error) {
          console.log('error', error);
        }
      }
    };
    fetchData();
  }, []);

  const modules = useMemo(
    () => ({
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize'],
      },
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: Font.whitelist }],
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['image', 'link'],
        ],
        handlers: {
          image: imgHandler,
        },
      },
    }),
    []
  );

  function imgHandler() {
    const tooltip = this.quill.theme.tooltip;
    const originalSave = tooltip.save;
    const originalHide = tooltip.hide;

    tooltip.save = function () {
      const range = this.quill.getSelection(true);
      const value = this.textbox.value;
      if (value) {
        this.quill.insertEmbed(range.index, 'image', value, 'user');
      }
    };
    // Called on hide and save.
    tooltip.hide = function () {
      tooltip.save = originalSave;
      tooltip.hide = originalHide;
      tooltip.hide();
    };
    tooltip.edit('image');
    tooltip.textbox.placeholder = 'Dirección URL';
  }

  useEffect(() => {
    if (image && order && content) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [image, order, content]);

  const addTraining = async () => {
    if (!canSave) {
      return;
    }

    const trainingToAdd = { content: content, image: image, order: order };
    const routeParam = trainingId ? `/${trainingId}` : '';

    try {
      await fetch(`${url}/prv/trainings${routeParam}`, {
        method: trainingId ? 'PUT' : 'POST',
        headers: {
          Authorization: 'bearer ' + localStorage.getItem('inecip_access_token'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(trainingToAdd),
      });
      alert('Se guardo correctamente!');
      history.push('/trainings');
    } catch (err) {
      console.error(err);
      setCanSave(true);
    }
  };

  const changeExistingContent = (value) => {
    const trainingUpdate = {
      content: value,
      order: order,
      image: image,
    };
    setTraining(trainingUpdate);
    setContent(value);
  };

  return (
    <CRow>
      <CCol xs={12}>
        <Link to='/trainings'>
          <CButton type='button' color='info' id='button-addon1' style={{ marginBottom: '30px' }}>
            Volver
          </CButton>
        </Link>
      </CCol>
      <br />
      <CCol xs={12}>
        <CCard className='mb-4'>
          <CCardHeader>
            <strong style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inherit' }}>
              {trainingId ? `Editar "${training?.content?.replace(/(<([^>]+)>)/gi, '') ?? ''}"` : 'Nueva capacitación'}
            </strong>
          </CCardHeader>
          <CCardBody>
            <CInputGroup className='mb-3'>
              <CInputGroupText required>Contenido *</CInputGroupText>
              <ReactQuill
                style={{ paddingBottom: '50px', marginLeft: '20px', width: '70%' }}
                theme='snow'
                modules={modules}
                value={training ? training.content : ''}
                onChange={(value) => changeExistingContent(value)}
              />
            </CInputGroup>
            <CInputGroup className='mb-3'>
              <CInputGroupText required>Ruta de la imagen *</CInputGroupText>
              <CInput
                defaultValue={training ? training.image : ''}
                aria-label='Image '
                aria-describedby='basic-addon1'
                onChange={(e) => setImage(e.target.value)}
              />
            </CInputGroup>
            <CInputGroup className='mb-3'>
              <CInputGroupText required>Orden *</CInputGroupText>
              <CInput
                defaultValue={training ? training.order : ''}
                aria-label='Order '
                aria-describedby='basic-addon1'
                onChange={(e) => setOrder(e.target.value)}
                type='number'
              />
            </CInputGroup>
            <CButton
              style={{ float: 'right' }}
              onClick={addTraining}
              disabled={!canSave}
              type='button'
              color='danger'
              id='button-addon1'>
              {trainingId ? 'Guardar' : 'Crear capacitación'}
            </CButton>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default TrainingForm;
