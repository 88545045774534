import React, {useEffect, useState} from 'react'
import { CCard, CSpinner, CButton, CLabel, CInput,CCardBody, CCardHeader, CCol, CRow, CInputGroup, CInputGroupText, CTextarea } from '@coreui/react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import ProblemsDropdown from './ProblemsDropdown';
import ContentsForm from '../contentsForm/ContentsForm'


const url = process.env.REACT_APP_BACK_URL;


const DimensionsForm = (props) => {

    const dispatch = useDispatch();
    const allResources = useSelector((state) => state.resources.data);
    const history = useHistory();
    if (!allResources) {
      history.push("/resources")

    }
    const resource = useSelector((state) => props.match.params.resourceId ? state.resources.data[props.match.params.resourceId] : undefined)
    const [title, setTitle] = useState(resource ? resource.title : '')
	const [description, setDescription] = useState(resource ? resource.description : '')
    const [selectedProblem, setSelectedProblem] = useState(resource ? resource.parent_slug : '')
    const [contents, setContents] = useState(resource && resource.contents ? resource.contents : [])
	const [canSave, setCanSave] = useState(!!resource)
    const [saving, setSaving] = useState(false)
    const [validUrl, setValidUrl] = useState(true)
    const [error, setError] = useState(false)


	useEffect(() => {
		setCanSave(title && description && selectedProblem && validUrl)
	}, [title, description, selectedProblem, validUrl])

	const addResource = () => {
        let resourceToAdd = {element: {title, description, order: allResources.length + 1, parent_slug: selectedProblem, color: "#eeeeee"}, contents}
        let routeParam = resource ? `/${resource.slug}` : ''
        setSaving(true)
		if (canSave) {
            fetch(`${url}/prv/elements/resources` + routeParam,
                {
                    method: resource ? "PUT" : "POST",
                    headers: {
                        'Authorization': 'bearer ' + localStorage.getItem('inecip_access_token'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                },
                body: JSON.stringify(resourceToAdd)
            })
            .then( () => {
                dispatch({type:'addResource', resource: resourceToAdd})
            }).then(() => {
                setSaving(false);
                history.push("/resources")
            })
            .catch(() => setError(true))
		}
	}


    return (
        <CRow>
            <CCol xs={12}>
            <Link to='/resources'>
                <CButton type="button" color="info" id="button-addon1" style={{marginBottom:'30px'}}>
                Volver
                </CButton>
            </Link>
            </CCol>
            <br/>
            <CCol xs={12}>
            <CCard className="mb-4">
                <CCardHeader>
                <strong>{resource ? 'Editar "' + resource.title + '"' : 'Nuevo insumo'}</strong>
                </CCardHeader>
                <CCardBody>
                <CInputGroup className="mb-3">
                <CInputGroupText required>Título del insumo</CInputGroupText>
                    <CInput
                        defaultValue={title}
                        aria-label="Titulo  "
                        aria-describedby="basic-addon1"
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </CInputGroup>
                <CInputGroup className="mb-3">
                    <CInputGroupText required>Descripción del insumo</CInputGroupText>
                    <CTextarea placerholder="Descripción" defaultValue={description} onChange={(e) => setDescription(e.target.value)} component="textarea" aria-label="With textarea"></CTextarea>
                </CInputGroup>
                <br/>
                <ProblemsDropdown
                    setSelectedProblem={setSelectedProblem}
                    resource={resource}
                    selectedProblem={selectedProblem}
                />
                <br/>
                <ContentsForm element={resource} setContents={setContents} setValidUrl={setValidUrl}/>
                {error && <CLabel>Hubo un error al intentar guardar</CLabel>}
                <CButton style={{float: 'right'}} onClick={addResource} disabled={!canSave} type="button" color="danger" id="button-addon1">
                    {!saving ? resource ? 'Guardar' : 'Crear insumo' : <CSpinner size="sm"/>}
                </CButton>
                </CCardBody>
            </CCard>
            </CCol>
        </CRow>
        )
    }

export default DimensionsForm
