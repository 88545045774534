import React, { useEffect, useState, useMemo } from 'react';
import {
  CCard,
  CSpinner,
  CButton,
  CLabel,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CInputGroup,
  CInputGroupText,
  CTextarea,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import AxesDropdown from './AxesDropdown';
import ContentsForm from '../contentsForm/ContentsForm';
import ReactQuill, { Quill } from 'react-quill';
import { cleanLinebreaks } from '../utils';

const Font = Quill.import('formats/font');
Font.whitelist = ['Roboto', 'Raleway', 'Alegreya-Sans'];
Quill.register(Font, true);

const url = process.env.REACT_APP_BACK_URL;

const DimensionsForm = ({ match }) => {
  ///////dimensions
  const allDimensions = useSelector((state) => state.dimensions.data);
  const history = useHistory();

  const dimension = useSelector(({ dimensions }) =>
    match.params.dimensionId && dimensions?.data ? dimensions?.data[match.params.dimensionId] : undefined
  );
  const [title, setTitle] = useState(dimension?.title ?? '');
  const [titleStyle, setTitleStyle] = useState(dimension?.title_style ?? title);
  const [description, setDescription] = useState(dimension?.description ?? '');
  const [selectedAxis, setSelectedAxis] = useState(dimension?.parent_slug ?? '');
  const [canSave, setCanSave] = useState(!!dimension);
  const [contents, setContents] = useState(dimension?.contents ?? []);
  const [validUrl, setValidUrl] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (title && selectedAxis && validUrl) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [title, description, selectedAxis, validUrl]);

  const addDimension = () => {
    const dimensionToAdd = {
      element: {
        title,
        title_style: titleStyle,
        description,
        order: allDimensions.length + 1,
        parent_slug: selectedAxis,
        color: '#eeeeee',
      },
      contents,
    };
    const routeParam = dimension ? `/${dimension.slug}` : '';
    setSaving(true);
    if (canSave) {
      fetch(`${url}/prv/elements/dimensions` + routeParam, {
        method: dimension ? 'PUT' : 'POST',
        headers: {
          Authorization: 'bearer ' + localStorage.getItem('inecip_access_token'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dimensionToAdd),
      })
        .then(() => {
          dispatch({ type: 'addDimension', dimension: dimensionToAdd });
        })
        .then(() => {
          setSaving(false);
          history.push('/dimensions');
        })
        .catch(() => setError(true));
    }
  };

  const handleTitleChange = (value, delta, source, editor) => {
    setTitleStyle(value);
    setTitle(cleanLinebreaks(editor.getText()));
  };

  const modules = useMemo(
    () => ({
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize'],
      },
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: Font.whitelist }],
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['image', 'link'],
        ],
      },
    }),
    []
  );

  if (!allDimensions) {
    history.push('/dimensions');
    return null;
  }

  return (
    <CRow>
      <CCol xs={12}>
        <Link to='/dimensions'>
          <CButton type='button' color='info' id='button-addon1' style={{ marginBottom: '30px' }}>
            Volver
          </CButton>
        </Link>
      </CCol>
      <br />
      <CCol xs={12}>
        <CCard className='mb-4'>
          <CCardHeader>
            <strong>{dimension ? 'Editar dimensión' : 'Nueva dimensión'}</strong>
          </CCardHeader>
          <CCardBody>
            <CInputGroup className='mb-3'>
              <CInputGroupText required>Título de la dimension</CInputGroupText>
              <ReactQuill
                style={{ paddingBottom: '50px', marginLeft: '20px', width: '70%' }}
                theme='snow'
                modules={modules}
                value={titleStyle}
                onChange={handleTitleChange}
              />
            </CInputGroup>
            <AxesDropdown setSelectedAxis={setSelectedAxis} dimension={dimension} selectedAxis={selectedAxis} />
            <br />
            <ContentsForm element={dimension} setContents={setContents} setValidUrl={setValidUrl} />
            {error && <CLabel>Hubo un error al intentar guardar</CLabel>}
            <CButton
              style={{ float: 'right' }}
              onClick={addDimension}
              disabled={!canSave}
              type='button'
              color='danger'
              id='button-addon1'>
              {!saving ? dimension ? 'Guardar' : 'Crear dimension' : <CSpinner size='sm' />}
            </CButton>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default DimensionsForm;
