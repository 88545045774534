export default (axesState = [], actionParams) => {
    switch (actionParams.type) {
      case 'setAxes':
        return {...axesState, data: actionParams.data}
      case 'addAxis':
        if (actionParams.axis.index) {
          let newAxes = axesState.data;
          newAxes[actionParams.axis.index] = actionParams.axis;
          return { ...axesState, data: newAxes }  
        }
        return { ...axesState, data: [...axesState.data, actionParams.axis] }      
      case 'removeAxis':
          let newAxes = axesState.data;
          newAxes = newAxes.filter(axis => axis.title !== actionParams.axis.title)
          return { ...axesState, data: newAxes }  
      default:
        return axesState
    }
  }