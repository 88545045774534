import React, {useEffect, useState, useRef} from 'react'
import { CButton, CSpinner ,CCard, CRow, CCol, CCardHeader, CCardBody, CListGroup, CListGroupItem, CInput } from '@coreui/react'
import { Link, useHistory } from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import CIcon from '@coreui/icons-react'
const url = process.env.REACT_APP_BACK_URL;

const Users = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const users = useSelector((state) => state.users.data);
    const activeUser = localStorage.getItem('inecip_active_user');
    const [stateUsers, setStateUsers] = useState([])
    const [isLoading, setLoading] = useState(false)
    const initialRender = useRef(true)

    const remove = (user) => {
      setLoading(true);
      fetch(`${url}/prv/users/` + user.uuid, {method: "DELETE",
        headers: {
          'Authorization': 'bearer ' + localStorage.getItem('inecip_access_token'),
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }})
      .then(() => dispatch({type: 'removeUser', user}))
    }

    const filterUsers = (e) => {
      setStateUsers(users.filter((user) => (user.firstname.toLowerCase() + ' ' + user.lastname.toLowerCase()).includes(e.target.value.toLowerCase())))
    }

    useEffect(() => {
      setLoading(true)
      fetch(`${url}/prv/users`, {
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem('inecip_access_token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'}})
      .then(response => response.json())
      .then(response => dispatch({type: 'setUsers', data: response.users}))
      .then(() => initialRender.current = false)
      .catch(() => history.push('/'))
    },[])

    useEffect(() => {
      if (!initialRender.current) {
        setStateUsers(users);
        setLoading(false);
      }
    }, [users])

    return(
        <>
          {isLoading ? <CSpinner style={{display:'block', margin: '0 auto'}}/> : 
        <CRow>
        <CCol xs={12}>
        <CCard className="mb-4">
            <>
              <CCardHeader>
                <strong style={{verticalAlign:'sub'}}>Listado de usuarios</strong>
                {users && users.length > 0 && <CInput
                    type="dimensionsFilter"
                    id="dimensions"
                    name="dimensions"
                    placeholder="Filtrar usuarios por nombre"
                    className="dimensionsFilter"
                    onChange={filterUsers}
                  />
                }
                <Link to="/users/new">
                    <CButton type="button" color="danger" id="button-addon1" style={{float:'right'}}>
                        Nuevo usuario
                    </CButton>
                </Link>
              </CCardHeader>
              {stateUsers.length > 0 &&
                <CCardBody>
                    <CListGroup>
                        {stateUsers.map((user) => (
                            <div style={{position: 'relative'}}>
                              <Link to={"/users/" + user.id}>
                                  <CListGroupItem key={user.id}>
                                      <span style={{textDecoration: 'none', color: 'black'}}>{user.firstname + ' ' + user.lastname}</span>
                                  </CListGroupItem>
                              </Link>
                              { user.email !== activeUser && <div onClick={() => remove(user)} className="dimensionRemove">
                                <CIcon name='cil-trash' style={{color: 'black'}}/>
                              </div>}
                            </div>
                        ))}
                    </CListGroup>
                </CCardBody>}
            </>
          </CCard>
        </CCol>
        </CRow>
            }
    </>
    )

}

export default Users;