import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CImg,
  CLabel,
  CSpinner
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
const url = process.env.REACT_APP_BACK_URL;

const Login = () => {
  
  const history = useHistory()

  const authenticate = () => {
    setLoading(true)
    fetch(`${url}/login`, {
      method: 'post', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password
      })
    })
    .then((response) => response.json())
    .then(({data}) => {
      setError('')
      setLoading(false)
      localStorage.setItem('inecip_access_token', data.token);
      localStorage.setItem('inecip_active_user', email);
      history.push("/inicio");
    })
    .catch(() => {
      setLoading(false)  
      setError('Error de autenticatión')
    })
  }

  const onKeyUp = (e) => {
    if (e.charCode === 13) {
      authenticate();
    }
  }

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CImg src={'logo.png'} style={{height: '5%', position: 'absolute', top: '20px', right:'20px'}} />
                  <CForm>
                    <h1>Accedé con tu usuario</h1>
                    <p className="text-muted">Ingresá tus credenciales</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)}
                        className="loginInput" 
                        type="email"
                        onKeyPress={onKeyUp}>
                      </CInput>
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput 
                        onChange={(e) => setPassword(e.target.value)} 
                        value={password} 
                        className="loginInput" 
                        type="password" 
                        onKeyPress={onKeyUp}
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs="6">
                        {/*<Link to='/inicio'> */}
                          <CButton onClick={authenticate} color="danger" className="px-4">Ingresar</CButton>
                        {/* </Link> */}
                      </CCol>
                      <CCol xs="6" className="text-right">
                        {loading && <CSpinner />}
                        {error && !loading && <CLabel color="red">{error}</CLabel>}
                        {/* <CButton color="link" className="px-0">Olvidaste tu contraseña?</CButton> */}
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
